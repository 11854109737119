.gx-app-login-wrap {
    position: relative;
}

.visa-card-logo {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.visa-card-logo img {
    width: 100px; /* Налаштуйте розмір лого за потреби */
    height: auto;
}